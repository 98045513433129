import React from "react"
import TextLoop from "react-text-loop"
// import { Link } from "gatsby"
// import { Parallax } from "react-parallax"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import Slider from "../components/Slider"
import WhoAreWe from "../components/elements/WhoAreWe"
import AboutHeader from "../components/elements/AboutHeader"
import AboutVisionMission from "../components/elements/AboutVisionMission"
import Services from "../components/Services"
import Breadcrumb from "../components/Breadcrumb"
import TabOne from "../components/Tabs/TabOne"

import { Background } from "../config/assets"

const title = ""
const description = ""

const IndexPage = () => (
  <Layout>
    <SEO title="About TEKMENS" />

    {/* Start Slider Area   */}
    <AboutHeader />
    {/* End Slider Area   */}

    {/* Start Vission Mission Area */}
    <div className="about-area about-position-top pb--120 bg_color--2">
      <AboutVisionMission />
    </div>
    {/* End Vission Mission Area */}

    {/* Start Service Area  */}
    <div className="service-area creative-service-wrapper pt--90 pb--50 bg_color--1">
      {/* <Parallax bgImage={Background.Paralax_Slider_01} strength={700}> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* <div className="section-title text-center service-style--1 mb--30"> */}
            <div className="section-title text-center mb--30">
              <h2 className="title">WHAT WE DO</h2>
              <p>
                Tekmens business comprises of three major sector, are{" "}
                <strong>Construction</strong>, <strong>Envrionment</strong> and{" "}
                <strong>MEP</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="row service-one-wrapper mt--30">
          <WhoAreWe variant="dark" />
        </div>

        <div className="product-explore-button mt--50 text-center">
          <a
            className="rn-button-style--2 btn-solid btn-center"
            href="/sectors"
          >
            Explore Our Sectors
          </a>
        </div>
      </div>
      {/* </Parallax> */}
    </div>
    {/* End Service Area  */}
  </Layout>
)

export default IndexPage
